<template>
  <div>
    <el-form
      :model="payInfo"
      :rules="rules"
      ref="payInfo"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="商品id" prop="productId">
        <el-select v-model="payInfo.productId" placeholder="请选择会员类型">
          <el-option
            v-for="item in productIdRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="totalAmountByproductId(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="购买数量" prop="buyNum">
        <el-input-number
          v-model="payInfo.buyNum"
          :min="1"
          :max="10"
          label="购买数量"
          @click="totalAmountByProductNum(payInfo.buyNum)"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="商品总价" prop="totalAmount">
        {{ payInfo.totalAmount }}
      </el-form-item>
      <el-form-item label="优惠券id" prop="couponId">
        <el-select v-model="payInfo.couponId" placeholder="请选择优惠券类型">
          <el-option
            v-for="item in couponIdRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="totalAmountByCouponId(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="支付总价（含优惠券）" prop="payAmount">
        {{ payInfo.payAmount }}
      </el-form-item>
      <el-form-item label="支付类型id" prop="payTypeId">
        <el-select v-model="payInfo.payType" placeholder="请选择支付类型">
          <el-option
            v-for="item in payTypeRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="payTypeId(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="发票类型id" prop="billTypeId">
        <el-select v-model="payInfo.billType" placeholder="请选择发票类型">
          <el-option
            v-for="item in billTypeRegion"
            :label="item.label"
            :value="item.value"
            :key="item.value"
            @click="billTypeId(item.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="开票人姓名" prop="bill">
        <el-input
          v-model="payInfo.billHeader"
          :disabled="isBill"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="发票内容" prop="bill">
        <el-input
          v-model="payInfo.billContent"
          :disabled="isBill"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="开票人电话" prop="bill">
        <el-input
          v-model="payInfo.billReceiverPhone"
          :disabled="isBill"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item label="开票人邮箱" prop="bill">
        <el-input
          v-model="payInfo.billReceiverEmail"
          :disabled="isBill"
          style="width: 200px"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="createPay(payInfo)"
          v-loading.fullscreen.lock="fullscreenLoading"
          >立即创建
        </el-button>
        <el-button @click="createPay(payInfo)">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      productPriceList: (state) => state.Pay.productPriceList,
      buyNum: (state) => state.Pay.buyNum,
      productId: (state) => state.Pay.productId,
      couponId: (state) => state.Pay.couponId,
      callBackInfo: (state) => state.Pay.callbackInfo,
    }),
  },
  data() {
    return {
      payInfo: {
        token: "",
        productId: 0,
        buyNum: 1,
        totalAmount: 0.0,
        payAmount: 0.0,
        payType: "",
        billType: "",
        clientType: "PC",
        billHeader: "cl",
        billContent: "This is content 1",
        billReceiverPhone: "18881793021",
        billReceiverEmail: "203746783@qq.com",
        couponId: 0,
      },
      productIdRegion: [
        {
          label: "---请选择会员类型---",
          value: 0,
        },
        {
          label: "青铜会员",
          value: 1,
        },
        {
          label: "黄金会员",
          value: 2,
        },
        {
          label: "黑金会员",
          value: 3,
        },
      ],
      couponIdRegion: [
        {
          label: "---请选择优惠券类型---",
          value: 0,
          fullPrice: 50,
          reducePrice: 0.0,
        },
        {
          label: "满0.1减0.01",
          value: 1,
          fullPrice: 0.1,
          reducePrice: 0.01,
        },
        {
          label: "满100减15",
          value: 2,
          fullPrice: 100,
          reducePrice: 0.01,
        },
        {
          label: "满300减50",
          value: 3,
          fullPrice: 300,
          reducePrice: 0.01,
        },
      ],
      payTypeRegion: [
        {
          label: "---请选择支付类型---",
          value: "",
        },
        {
          label: "微信支付",
          value: "WECHAT",
        },
        {
          label: "支付宝支付",
          value: "ALIPAY",
        },
      ],
      billTypeRegion: [
        {
          label: "---请选择发票类型---",
          value: "",
        },
        {
          label: "电子发票",
          value: "ELE_BILL",
        },
        {
          label: "纸质发票",
          value: "PAPER_BILL",
        },
      ],
      rules: {
        productId: [
          { required: true, message: "请选择会员类型", trigger: "blur" },
        ],
        buyNum: [
          { required: true, message: "请输入商品购买数量", trigger: "blur" },
        ],
        totalAmount: [{ required: true, trigger: "change" }],
        couponId: [
          { required: false, message: "请选择优惠券类型", trigger: "blur" },
        ],
        payAmount: [{ required: true, trigger: "change" }],
        payTypeId: [
          { required: true, message: "请选择支付类型", trigger: "blur" },
        ],
        clientTypeId: [
          { required: true, message: "请选择客户端类型", trigger: "blur" },
        ],
        billTypeId: [
          { required: false, message: "请选择客户端类型", trigger: "blur" },
        ],
        bill: [
          { required: false, message: "请填写开票信息类型", trigger: "change" },
        ],
      },
      fullscreenLoading: false,
      isBill: true,
    };
  },
  methods: {
    createWechatPay(payInfo) {
      this.$store.dispatch("createPay", payInfo);
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
        this.$router.push({ name: "wechatQr" });
      }, 2000);
    },
    generatePayForm() {
      const div = document.createElement("div");
      div.innerHTML = this.callBackInfo;
      document.body.appendChild(div);
      document.forms[1].submit();
    },
    createAlipay(payInfo) {
      this.$store.dispatch("createPay", payInfo);
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
        this.generatePayForm();
      }, 2000);
    },
    createPay(payInfo) {
      if (this.payInfo.clientType === "PC") {
        if (this.payInfo.payType === "WECHAT") {
          this.createWechatPay(payInfo);
        } else if (this.payInfo.payType === "ALIPAY") {
          this.createAlipay(payInfo);
        }
      } else if (this.payInfo.clientType === "H5") {
        if (this.payInfo.payType === "ALIPAY") {
          this.createAlipay(payInfo);
        }
      }
    },
    totalAmountByproductId(productId) {
      if (productId >= 1) {
        this.payInfo.productId = productId;
        this.$store.commit("SET_BUY_PRODUCT_ID", productId);
        this.payInfo.totalAmount = this.floatMulCalculation(
          this.productPriceList[productId].productPrice,
          this.buyNum
        );
        this.couponFullMinus(
          this.couponIdRegion[this.couponId].fullPrice,
          this.couponIdRegion[this.couponId].reducePrice
        );
      } else {
        this.payInfo.totalAmount = 0.0;
        this.payInfo.payAmount = 0.0;
        this.$store.commit("SET_BUY_PRODUCT_ID", 0);
      }
    },
    totalAmountByProductNum(productNum) {
      if (this.productId !== 0) {
        this.payInfo.totalAmount = this.floatMulCalculation(
          this.productPriceList[this.productId].productPrice,
          productNum
        );
        this.couponFullMinus(
          this.couponIdRegion[this.couponId].fullPrice,
          this.couponIdRegion[this.couponId].reducePrice
        );
        this.$store.commit("SET_BUY_NUM", productNum);
      }
      this.$store.commit("SET_BUY_NUM", productNum);
    },
    couponFullMinus(fullPrice, reducePrice) {
      if (this.payInfo.totalAmount >= fullPrice) {
        this.payInfo.payAmount = this.floatSubCalculation(
          this.payInfo.totalAmount,
          reducePrice
        );
      } else {
        this.payInfo.payAmount = this.payInfo.totalAmount;
      }
    },
    totalAmountByCouponId(couponId) {
      this.couponIdRegion.forEach((item, index) => {
        if (item.value === couponId) {
          this.$store.commit("SET_BUY_COUPON_ID", couponId);
          this.couponId = this.couponIdRegion[index].value;
          this.couponFullMinus(item.fullPrice, item.reducePrice);
          if (this.payInfo.payAmount < 0.0) {
            this.payInfo.payAmount = 0.0;
          }
        }
      });
    },
    payTypeId(payTypeId) {
      this.payTypeRegion.forEach((item, index) => {
        if (item.value === payTypeId) {
          this.payInfo.payType = this.payTypeRegion[index].value;
        }
      });
    },
    billTypeId(billType) {
      this.isBill = true;
      this.payTypeRegion.forEach((item, index) => {
        if (item.value === billType) {
          this.payInfo.billType = this.billTypeRegion[index].value;
        }
      });
      if (billType === "ELE_BILL" || billType === "PAPER_BILL") {
        this.isBill = false;
      }
    },
    floatMulCalculation(num1, num2) {
      const num1Str = num1.toString();
      const num2Str = num2.toString();
      let mathNum = 0;
      try {
        mathNum += (num1Str.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 * num2;
      }
      try {
        mathNum += (num2Str.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 * num2;
      }
      return (
        (Number(num1Str.replace(".", "")) * Number(num2Str.replace(".", ""))) /
        Math.pow(10, mathNum)
      );
    },
    floatDivCalculation(num1, num2) {
      const num1Str = num1.toString();
      const num2Str = num2.toString();
      let mathNum = 0;
      try {
        mathNum += (num1Str.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 / num2;
      }
      try {
        mathNum += (num2Str.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 / num2;
      }
      const num1Div = this.floatMulCalculation(num1, Math.pow(10, mathNum));
      const num2Div = this.floatMulCalculation(num2, Math.pow(10, mathNum));
      return Number(num1Div) / Number(num2Div);
    },
    floatAddCalculation(num1, num2) {
      // eslint-disable-next-line no-unused-vars
      let num1Str = 0;
      // eslint-disable-next-line no-unused-vars
      let num2Str = 0;
      // eslint-disable-next-line no-unused-vars
      let mathNum = 0;
      try {
        num1Str = (num1.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 + num2;
      }
      try {
        num2Str = (num2.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 + num2;
      }
      mathNum = Math.pow(10, Math.max(num1Str, num2Str));
      return (
        Math.round(
          this.floatMulCalculation(num1, mathNum) +
            this.floatMulCalculation(num2, mathNum)
        ) / mathNum
      );
    },
    floatSubCalculation(num1, num2) {
      // eslint-disable-next-line no-unused-vars
      let num1Str = 0;
      // eslint-disable-next-line no-unused-vars
      let num2Str = 0;
      // eslint-disable-next-line no-unused-vars
      let mathNum = 0;
      try {
        num1Str = (num1.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 - num2;
      }
      try {
        num2Str = (num2.toString().split(".")[1] || []).length;
      } catch (e) {
        return num1 - num2;
      }
      mathNum = Math.pow(10, Math.max(num1Str, num2Str));
      return (
        Math.round(
          this.floatMulCalculation(num1, mathNum) -
            this.floatMulCalculation(num2, mathNum)
        ) / mathNum
      );
    },
  },
};
</script>

<style lang="scss">
.demo-ruleForm {
  margin: 0 80vh 0 5vh;
  width: 50%;
  padding: 20px;
}
</style>
